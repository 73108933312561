<template>
  <div class="hotnews">
    <van-list :v-model="true" :finished="true">
      <dl class="new-item" v-for="item in newList" :key="item.id" :name="item.id">
        <dt class="icon">
          <img src="../assets//icon-new.png" />
        </dt>
        <dd class="title">
          <router-link :to="{path:'Detail',query:{id:item.id}}">{{decodeURIComponent(item.title.replace(/\+/g, "%20"))}}</router-link>
        </dd>
      </dl>
    </van-list>
  </div>
</template>
<script>
export default {
  name: "HotNew",
  data() {
    return {
      newList: []
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    loadData: function() {
      var that = this;
      var params = { top: 5 };
      that.$api.getHotnew(params).then(res => {
        that.newList = res.data;
      });
    }
  }
};
</script>
<style scoped>
.hotnews{
  background: #fff;
  border-radius: .5rem;
  padding: 1rem;
  margin-bottom: 2rem;
}
.new-item {
  height: 5rem;
  line-height: 5rem;
  display: flex;
  margin: 0;
  padding: 0;
}
.new-item .icon {
  width: 4rem;
  height: 4rem;
  margin:0 .6rem 0 0;
  padding: 0;
}
.new-item .icon img {
  width: 4rem;
  height: 4rem;
}
.new-item .title {
  margin: 0;
  height: 4rem;
  padding: 0;
  overflow: hidden;
  flex: 1;
  font-size: 2.4rem;
  text-align: left;
}
.new-item .title a {
  height: 4rem;
  line-height: 4rem;
  color: #333;
  display: block;
  font-size: 2.4rem;
}
.van-list .cell-item {
  padding: 1rem 0;
}
.van-list .cell-item::after {
  width: 100%;
}
</style>